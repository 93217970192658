<script setup lang="ts">
import { SelectValue, type SelectValueProps } from 'radix-vue';

const props = defineProps<SelectValueProps>();
</script>

<template>
    <SelectValue class="text-stone-700 dark:text-stone-200" v-bind="props">
        <slot />
    </SelectValue>
</template>
